@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@import 'nav';
@import 'slider';
@import 'hero';
@import "footer";
@import "iframeYt" ;
@import "karbala" ;

body {
    background-color: #202020;
    font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

.page_hero-is {
    img {
        width: 70%;
        height: 100%;
    }
    @media (max-width:767px) {
        height:35vh !important;
        img {
            width: 90%;
            height: 100% !important;
        }
    }
}
  .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  p::selection{
    background: #7c0303 !important;
    color: #fff !important;
}