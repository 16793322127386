nav {
    background-color: #202020;

    ul {
        li {
            .page_title-is {
                color: #fff;

                @media (min-width:1200px) {
                    margin: 0px 10px;

                }

                &:hover {
                    color: red !important;
                }
            }
        }
    }

    .live_box-is {
        #live_link-is {
            color: #fff;


            &:hover {
                color: red;
            }
        }

        @media (max-width:768px) {
            margin-bottom: 20px !important;
        }
    }

    #blood_2-is {
        @media (max-width:1022px) {
           display: none;
        }
    }
}

html {
    overflow-x: hidden !important;
}
.active {
    color: red !important;
}

@media (max-width:768px){
    .navbar{
        .lang{
            transform: translateX(0) !important;
        }
        
    }
}