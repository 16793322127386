.karbala {
  font-family: "Inter";
  .page-head {
    width: 100%;
    display: flex;
    justify-content: center;
    .text-box {
      width: 75%;
      text-align: center;
      color: #ff2828;
      h2 {
        border-radius: 10px;
        padding: 5vh 0;
        border: 2px solid #ff2828;
        font-weight: bolder;
      }
      img {
        margin: 5vh 0;
        width: 100%;
      }
    }
  }
  .main-part {
    h3{
        color: #ff2828;
        padding: 2vh 0;
        border-bottom: 1px solid #ff2828;
        width: auto;
    }
    p{
        margin: 4vh 0;
        color: #fff;
    }
  }
}
