#banner-is {
    position: relative;

    .divbox {
        position: absolute;
        top: 0%;
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
   
    //   background-color: red;
    }

    img {
        width: 100%;
        height: 89vh !important;
        z-index: -1;
        animation: grow 10s !important;


        @media (max-width:530px) {
            height: 60vh !important;
         
        }

       
    }

    @keyframes grow {
        0%, 20%{
          transform: scale(1);
        }
        20%, 50%{
          transform: scale(1.04);
        }
       50%, 100%{
          transform: scale(1.04);
      }
    //   75%,100%{ 
    //         transform: scale(1);
    //     }
      }

    h1 {


        color: #fff;
        font-size: 100px !important;
        font-family: 'Bebas Neue', sans-serif;
        letter-spacing: 10px;

        @media (max-width:530px) {
            font-size: 42px !important;
        }

        @media (max-width:320px) {
            font-size: 36px !important;
        }
    }

}

.swiper {
    .swiper-button-prev {
        color: #fff;
        font-weight: 700;
        margin-left: 20px !important;

        @media (max-width:768px) {
            margin-left: 0px !important;
        }
    }

    .swiper-button-next {
        color: #fff;
        font-weight: 700;
        margin-right: 20px !important;

        @media (max-width:768px) {
            margin-right: 0px !important;
        }
    }

    .swiper-pagination-bullet {
        background-color: #fff !important;
        width: 12px;
        height: 12px;
    }
}